function manageElements() {
    if ($("#categoryOption").val()) {
        if ($('input[id="category_id"]').length) {
            $('input[id="category_id"]').val($("#categoryOption").val());
        }

        $("#clientOption").parent().show();
    } else {
        $("#clientOption").parent().hide();
    }

    if ($("#clientOption").val()) {
        if ($('input[id="client_id"]').length) {
            $('input[id="client_id"]').val($("#clientOption").val());
        }
    }

    $(document).trigger("triggerElements");
}

$(document).ready(function () {
    $(document).on('refreshPage', function() {
        location.reload();
    });

    manageElements();

    const fileForm = $("#showFileForm");

    $("#categoryOption").on("change", function (ev) {
        if (
            fileForm &&
            $(this).val() &&
            $(this).val() !== ""
        ) {
            fileForm.removeAttr("disabled");
        } else {
            fileForm.attr("disabled", "disabled");
        }

        const categoryID = $(this).val();

        $("#clientOption").parent().show();

        $("#file").hide();
        $("#confirmFile").hide();

        $.ajax({
            method: "get",
            url: window.location.origin + "/global/set-session-variable",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            dataType: "json",
            data: {
                key: "active_category",
                id: categoryID,
            },
        }).success(function (data) {
            if (data.status === 0) {
                if (data.clients !== null) {
                    const selectElement = $("#clientOption");
                    selectElement.empty();

                    const defaultOption = $("<option>")
                        .attr("value", "")
                        .text("--------------");
                    selectElement.append(defaultOption);

                    // Iterate over the JSON object and create option elements
                    $.each(data.clients, function (key, value) {
                        const option = $("<option>")
                            .attr("value", key)
                            .text(value);
                        selectElement.append(option);
                    });
                }

                categoryOption = ev.target.value;
                clientOption = null;
                filterStatus = null;
                filterAgent = null;
                $("#statusFilter").val("");
                $("#agentFilter").val("");

                // Inject id-s to fileimport form
                if ($('input[id="category_id"]').length) {
                    $('input[id="category_id"]').val(categoryOption);
                }

                if ($(".dataTable").length > 0) {
                    $(".dataTable").DataTable().draw();
                }

                $(document).trigger("refreshPage");
            }
        });

        manageElements();
    });

    $("#clientOption").on("change", function (ev) {
        if (
            fileForm &&
            $(this).val() &&
            $(this).val() !== ""
        ) {
            fileForm.removeAttr("disabled");
        } else {
            fileForm.attr("disabled", "disabled");
        }

        const  clientID = this.value;

        $.ajax({
            method: "get",
            url: window.location.origin + "/global/set-session-variable",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            dataType: "json",
            data: {
                key: "active_client",
                id: clientID,
            },
        }).success(function (data) {
            if (data.status !== null && data.status === 0) {
                clientOption = ev.target.value;
                filterStatus = null;
                filterAgent = null;
                $("#statusFilter").val("");
                $("#agentFilter").val("");

                // Inject id-s to fileimport form
                if ($('input[id="client_id"]').length) {
                    $('input[id="client_id"]').val(clientOption);
                }

                if ($(".dataTable").length > 0) {
                    $(".dataTable").DataTable().draw();
                }

                $(document).trigger("refreshPage");
            }
        });
    });
});
